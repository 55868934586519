// src/pages/LessonsPage/index.jsx

import React, { useState } from 'react';
import { Footer } from '../Landing';
import { withFirebase } from '../../components/Firebase';
import '../../utils/shared.scss';
import './index.scss';
import LessonInterestForm from '../../components/LessonInterestForm';
import { Spinner, Modal, Button } from 'react-bootstrap';

const HEADER = 'LESSONS';
const TITLE = 'Interested in Golf Lessons with Our PGA Professionals?';
const SUBTITLE =
  "Contact us using the form below, and we'll get back to you shortly!";

const LessonsPage = (props) => {
  // State for loading and submission confirmation
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSubmission = (status) => {
    setIsLoading(false);
    if (status === 'success') {
      setShowConfirmation(true);
    }
    // No action needed for 'error' status here; error message is handled in the form component
  };

  const Title = (
    <div className="title-container">
      <div className="overlay">
        <h1 className="title text-white m-0">{HEADER}</h1>
      </div>
      <img
        src="https://storage.googleapis.com/thebunker-assets/thebunker/pexels-thomasleeward-2828723.jpg"
        alt="Lessons Background"
        width="100%"
        height="100%"
      />
    </div>
  );

  const InstructorsInfo = (
    <div className="instructors-info">
      <h2>Our Instructors</h2>
      <div className="instructor">
        <h3>Sean Madden, PGA</h3>
        <p>
          Email:{' '}
          <a href="mailto:sean@getinthebunker.golf">
            sean@getinthebunker.golf
          </a>
        </p>
        <p>
          Phone: <a href="tel:838-280-0323">838-280-0323</a>
        </p>
      </div>
      <div className="instructor">
        <h3>Phil Kaminski, PGA</h3>
        <p>
          Email:{' '}
          <a href="mailto:phil@getinthebunker.golf">
            phil@getinthebunker.golf
          </a>
        </p>
      </div>
    </div>
  );

  const LessonRates = (
    <div className="lesson-rates">
      <h2>Lesson Rates for Individual Instruction</h2>
      <ul>
        <li>1 Hour - $150</li>
        <li>30 Minutes - $80</li>
      </ul>
    </div>
  );

	const JuniorLessonRates = (
    <div className="lesson-rates">
      <h2>Lesson Rates for Juniors 16 and Under</h2>
      <ul>
        <li>1 Hour - $125</li>
        <li>30 Minutes - $65</li>
      </ul>
    </div>
  );

  const JuniorClinics = (
    <div className="junior-clinics">
      <h2>Junior Clinics</h2>
      <p>
        We offer 4-week sessions for ages 7-13. Contact Sean directly to sign
        up. Cost is $150 for all 4 weeks. The clinic will cover short game, full
        swing, and course play.
      </p>
      <h3>Dates/Times:</h3>
      <ul>
        <li>Guilderland: 10/20 - 11/10 | 9am - 10am</li>
        <li>Clifton Park: 12/1 - 12/22 | 9am - 10am</li>
        <li>North Greenbush: 1/5 - 1/26 | 9am - 10am</li>
        <li>Latham: 2/9 - 3/2 | 9am - 10am</li>
        <li>Saratoga: 3/9 - 3/30 | 9am - 10am</li>
      </ul>
    </div>
  );

  const Main = () => {
    return (
      <div className="main px-3 flex-fill h-100">
        <div className="pt-4">
          <h1 className="text-dark mb-4">{TITLE}</h1>
          <p className="ml-1">{SUBTITLE}</p>
          <hr />
          {InstructorsInfo}
          {LessonRates}
          {JuniorLessonRates}
          {JuniorClinics}
          <hr />
        </div>
        {/* Pass down setIsLoading and handleSubmission to the form */}
				<LessonInterestForm
					firebase={props.firebase}
					setIsLoading={setIsLoading}
					handleSubmission={handleSubmission}
					isLoading={isLoading}
				/>

      </div>
    );
  };

  return (
    <div className="lessons-container">
      {Title}
      {isLoading && (
        <div className="loading-overlay">
          <Spinner animation="border" variant="success" />
          <p>Submitting your inquiry...</p>
        </div>
      )}
      <Main />
      <Footer />

      {/* Submission Confirmation Modal */}
      <Modal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Thank You!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Your inquiry has been sent! We'll get back to you shortly.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => setShowConfirmation(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default withFirebase(LessonsPage);
